<template>
	<main>
		<div class="check-box">
			<a-link to="/admin/user" class="item" v-if="check.user">
				<div class="tit">新员工待审批：{{ check.user }}人</div>
				<div class="btn">
					<el-link type="primary">去处理</el-link>
				</div>
			</a-link>
			<a-link to="/admin/good/drop" class="item" v-if="check.drop">
				<div class="tit">设备报废待审批：{{ check.drop }}个</div>
				<div class="btn">
					<el-link type="primary">去处理</el-link>
				</div>
			</a-link>
		</div>
		<div class="stat-box">
			<div class="item">
				<div class="tit">现有设备总数</div>
				<div class="num">{{ stat.good }}</div>
			</div>
			<div class="item">
				<div class="tit">报废设备总数</div>
				<div class="num">{{ stat.drop }}</div>
			</div>
			<div class="item">
				<div class="tit">员工总数</div>
				<div class="num">{{ stat.user }}</div>
			</div>
			<div class="item">
				<div class="tit">维修员总数</div>
				<div class="num">{{ stat.role }}</div>
			</div>
			<div class="item">
				<div class="tit">工单总数</div>
				<div class="num">{{ stat.task }}</div>
			</div>
			<div class="item">
				<div class="tit">维修工单总数</div>
				<div class="num">{{ stat.task1 }}</div>
			</div>
			<div class="item">
				<div class="tit">保养工单总数</div>
				<div class="num">{{ stat.task2 }}</div>
			</div>
			<div class="item">
				<div class="tit">巡检工单总数</div>
				<div class="num">{{ stat.task3 }}</div>
			</div>
		</div>
	</main>
</template>
<script>
import {dashboard} from "@/api/admin";

export default {
	data() {
		return {
			stat: {},
			check: {},
		}
	},
	mounted() {
		this.getStat();
		this.getCheck();
	},
	methods: {
		getCheck() {
			dashboard.check().then((res) => {
				this.check = res.check;
			})
		},
		getStat() {
			dashboard.stat().then((res) => {
				this.stat = res.stat;
			})
		},
	}
}
</script>
<style lang="scss" scoped>
.check-box {margin-top: 30px;
	.item {display: flex;color: red;align-items: center;margin-bottom: 5px;
		.btn {margin-left: 15px;}
	}
	.item:nth-last-child(1) {margin-bottom: 0;}
}
.stat-box {margin-top: 30px;display: flex;flex-wrap: wrap;
	.item {width: calc((100% - 15px * 3) / 4);display: flex;flex-direction: column; padding: 30px; margin-right: 15px;margin-bottom: 15px;color: #fff;
		.num {font-size: 42px;font-weight: bold;margin-top: 10px;}
	}
	.item:nth-child(4n) {margin-right: 0;}
	.item:nth-child(1) {background: #34cc5f;}
	.item:nth-child(2) {background: #25a7fa;}
	.item:nth-child(3) {background: #ee5c5c;}
	.item:nth-child(4) {background: #e8a347;}
	.item:nth-child(5) {background: #563bfc;}
	.item:nth-child(6) {background: #dd892d;}
	.item:nth-child(7) {background: #fc528a;}
	.item:nth-child(8) {background: #83cddb;}
}
</style>
