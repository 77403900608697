<template>
	<main>
		<div class="el-content">
			<div class="info">
				<div class="p">您好，
					<i>{{ admin_info.nickname }}</i>
				</div>
				<div class="p">现在是：{{ time }}</div>
			</div>
			<components-stat v-show="tab_name === 'stat'"/>
		</div>
	</main>
</template>

<script>
import moment from "moment"
import ComponentsStat from "./components/index/Stat.vue"

export default {
	components: {ComponentsStat},
	data() {
		return {
			tab_name: "stat",
			timer: "",
			time: "",
		}
	},
	computed: {
		admin_info() {
			return this.$store.state.admin.admin_info
		}
	},
	mounted() {
		this.createTime();
	},
	methods: {
		createTime() {
			const step = () => {
				this.time = moment().format('YYYY年MM月DD日 HH:mm:ss')
			}
			step();

			clearInterval(this.timer);
			this.timer = setInterval(() => {
				step()
			}, 1000);

			this.$once('beforeDestroy', () => {
				clearInterval(this.timer);
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.info {font-size: 16px;line-height: 30px;
	i {font-weight: bold;}
}
</style>
